/*

App wide css classNames

*/

* {
  font-family: 'Roboto', sans-serif !important;
}

:root {
  --main-color: #58A618;
  --text-color: #2a2a2a;
  --white-color: #ffffff;
  --light-grey-color: #f5f5f5;
  --light-grey-color2: #cccccc;
  --error-color: red;
}

.dexcom-header {
  position: relative;
  float: left;
  margin-right: 120px;
  padding-top: 15px;
}

.nav-link {
  position: relative;
  float: left;
  color: var(--text-color);
  font-weight: normal;
  letter-spacing: 0px;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: -0.4px;
  margin-right: 39px;
  margin-top: 22px;
  height: calc(100% - 24px);
  text-align: middle;
}

.nav-link:hover {
  font-weight: bold;
}

.nav-link-active {
  font-weight: bold;
  border-bottom-style: solid;
  border-width: 4px;
  border-color: var(--main-color);
}

.link-button-text {
  color: var(--white-color);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.inline-link-button {
  position: absolute;
  top: 90px;
  right: 270px;
}

