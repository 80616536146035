#uploader {
  display: flex;
  justify-content: flex-end;
}

#uploaderFrame {
  position: absolute;
  top: -32px;
  width: 100%;
  height: calc(100vh + 32px);
  z-index: -1;
}
