#progress-bar {
  display: inline-block;
  margin: 10px;
  width: 240px;
  position: relative;
  border-radius: 0px;
  background: var(--main-color);
  opacity: 0.2
}

#progress-bar > span {
  display: block;
  height: 4px;
  background-color: var(--main-color);
  position: relative;
  transition: width 500ms ease-in-out;
}

#progress > span {
  display: inline-block;
  padding: 2px 0;
  vertical-align: top;
}
