label {
  height: 56px;
  width: 260px;
  color: var(--text-color);
  background-color: var(--light-grey-color);
  font-weight: normal;

  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;
}

input {
  color: var(--text-color);
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.36px;
  margin-top: 10px;
  height: 24px;
  width: calc(100% - 24px);
  border: none;
  background-color: var(--light-grey-color);
}

input[type='text']:disabled {
  color: darkgrey;
}

select {
  width: 350px;
  height: 38px;
  background: var(--white-color);
  border: 1px solid var(--light-grey-color2);
  border-radius: 4px;
  display: block;
  margin-left: 16px;
  margin-bottom: 6px;
}

.field-error {
  padding: 5px;
  font-size: 14px;
  color: var(--error-color);
}

.cancel-link-button-text {
  color: var(--text-color);
  font-size: 14px;
  font-weight: bold;
  height: 16px;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
