.messagebox-cancel {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

.messagebox-info {
  position: absolute;
  top: calc(75% + 24px);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  color: rgb(93, 96, 100);
  font-weight: normal;
  font-size: 16px;
  height: 95px;
  letter-spacing: 0px;
  text-align: center;
}

.dialog-image {
  position: absolute;
  top: calc(50% - 40px);
  left: 50%;
  margin-right: -50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
}
